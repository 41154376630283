import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminHome from "./Pages/AdminHome";
import AdminDLCs from "./Pages/Admin/DLCs";
import AdminMaps from "./Pages/Admin/Maps";
import AdminLearningResources from "./Pages/Admin/LearningResources";
import Home from "./Pages/Home";
import MapDetails from "./Pages/MapDetails";
import Nav from './Components/Nav';
import LogInForm from './Components/LogInForm';
import { onAuthChange, listDlcsAndMaps } from './api';
import './App.css';

function App() {
  const [hideLogin, setHideLogin] = useState(true);
  const [user, setUser] = useState(null);
  const [dlcs, setDlcs] = useState([]);
  const [fetchedData, setFetchedData] = useState(false);

  if (!fetchedData) {
    listDlcsAndMaps().then((dlcsAndMaps) => {
      setDlcs(dlcsAndMaps);
      setFetchedData(true);
    }).catch((error) => {
      console.log(error);
      setFetchedData(true);
    });
  }

  const onUserLogIn = (user) => {
    setUser(user);
    if (!hideLogin && user) {
      setHideLogin(true);
    }
  }

  useEffect(() => {
    onAuthChange().then(onUserLogIn);
  });

  const handleLogInClick = () => {
    setHideLogin((hideLogin) => !hideLogin);
  };

  return (
    <div className="App">
      <Nav dlcs={dlcs} user={user} handleLogInClick={handleLogInClick} />
      <LogInForm hideLogin={hideLogin} setHideLogin={setHideLogin} onUserLogIn={onUserLogIn} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/maps/:mapId" element={<MapDetails />} />
        <Route path="/admin" element={<AdminHome user={user} />} />
        <Route path="/admin/dlcs" element={<AdminDLCs user={user} />} />
        <Route path="/admin/maps" element={<AdminMaps user={user} />} />
        <Route path="/admin/learning-resources" element={<AdminLearningResources />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
