import React from "react";
import ImportMaps from "../../Components/Admin/ImportMaps";
import AddMap from "../../Components/Admin/AddMap";

export default function Maps({ user }) {
    return (
        <div>
            <h1>Maps</h1>
            <ImportMaps user={user} />
            <AddMap />
        </div>
    );
}