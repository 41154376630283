import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const MapDetails = React.lazy(() => import("../Components/MapDetails"));

export default function MapDetailsPage() {

    const { mapId } = useParams();

    return (
        <Suspense fallback={<Spinner animation="border" role="status" />}>
            <MapDetails mapId={mapId} />
        </Suspense>
    );
}