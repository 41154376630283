// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence, // OR: inMemoryPersistence, browserSessionPersistence; see https://firebase.google.com/docs/auth/web/auth-state-persistence
    signOut,
    connectAuthEmulator
} from "firebase/auth";

import {
    GeoPoint,
    Timestamp,
    getFirestore,
    collection,
    doc,
    addDoc,
    getDoc,
    getDocs,
    setDoc,
    query,
    where,
    writeBatch,
    connectFirestoreEmulator
} from "firebase/firestore";

import {
    getDatabase,
    ref,
    child,
    get,
    set,
    connectDatabaseEmulator
} from "firebase/database";

import {
    getStorage,
    ref as storageRef,
    getDownloadURL,
    uploadBytes,
    connectStorageEmulator
} from "firebase/storage";

import {
    getFunctions,
    connectFunctionsEmulator
} from "firebase/functions";

import { Parser } from "xml2js";
import Papa from "papaparse";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDd8-t24ikJ0H4DuzE3oYKnhaUI3_QbhP0",
    authDomain: "skylines-country.firebaseapp.com",
    projectId: "skylines-country",
    storageBucket: "skylines-country.appspot.com",
    messagingSenderId: "502715624173",
    appId: "1:502715624173:web:275dae509afdefe156fae4",
    measurementId: "G-XY22LH14JH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const db = getDatabase(app);
const storage = getStorage(app);

const importMapsUrl =
    "https://us-central1-skylines-country.cloudfunctions.net/app/importMaps";
//"http://localhost:5001/skylines-country/us-central1/app/importMaps";

const buildMenuUrl =
    "https://us-central1-skylines-country.cloudfunctions.net/app/buildMenu";
//"http://127.0.0.1:5001/skylines-country/us-central1/app/buildMenu";

//connectAuthEmulator(auth, "http://localhost:9099");
//connectFirestoreEmulator(firestore, 'localhost', 8080);
//connectDatabaseEmulator(db, "localhost", 9000);
//connectFunctionsEmulator(functions, "localhost", 5001);
//connectStorageEmulator(storage, "localhost", 9199);

export function onAuthChange() {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((newUser) => {
            resolve(newUser);
        });
    });
}

export function login(email, password) {
    return new Promise((resolve, reject) => {
        setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                resolve(userCredential.user);
            }).catch((error) => {
                // error.code, error.message
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function logout() {
    return new Promise((resolve, reject) => {
        signOut(auth).then(() => resolve(null)).catch((error) => reject(error));
    });
}

function isAdminLoggedIn(reject) {
    if (!auth.currentUser) {
        if (reject) {
            reject({
                code: "auth/no-user",
                message: "An admin must be signed in"
            });
        }
        return false;
    }
    return true;
}

function fsDocToDlc(doc) {
    const info = doc.data();
    return {
        id: doc.id,
        name: info.name,
        iconPath: info.iconPath,
        bannerPath: info.bannerPath
    };
}

function fsDocToDlcMap(doc) {
    const info = doc.data();
    return {
        id: doc.id,
        mapId: info.id,
        name: info.name
    };
}

function compareNamedItems(namedItem1, namedItem2) {
    if (namedItem1.name < namedItem2.name) {
        return -1;
    } else if (namedItem1.name > namedItem2.name) {
        return 1;
    } else {
        return 0;
    }
}

function compareDlcs(dlc1, dlc2) {
    // Base Game always goes first.
    if (dlc1.name === 'Base Game') {
        return -1;
    } else if (dlc2.name === 'Base Game') {
        return 1;
    }

    return compareNamedItems(dlc1, dlc2);
}

export function listDlcs() {
    return new Promise((resolve, reject) => {
        getDocs(collection(firestore, "dlcs")).then((snapshot) => {
            const dlcs = snapshot.docs.map(fsDocToDlc);
            dlcs.sort(compareDlcs);
            resolve(dlcs);
        }).catch((error) => {
            reject(error);
        });
    });
}

function listMapsForDlc(dlcDoc) {
    return new Promise((resolve, reject) => {
        getDocs(collection(firestore, "dlcs", dlcDoc.id, "maps")).then((mapsSnapshot) => {
            let dlc = fsDocToDlc(dlcDoc);
            let maps = mapsSnapshot.docs.map(fsDocToDlcMap);
            maps.sort(compareNamedItems);
            dlc.maps = maps;

            resolve(dlc);
        }).catch(reject);
    });
}

export function listDlcsAndMaps() {
    return new Promise((resolve, reject) => {
        get(child(ref(db), "menu")).then((menuSnapshot) => {
            if (menuSnapshot.exists()) {
                resolve(menuSnapshot.val());

            } else {
                getDocs(collection(firestore, "dlcs")).then((dlcsSnapshot) => {
                    Promise.all(dlcsSnapshot.docs.map(listMapsForDlc)).then((dlcs) => {
                        dlcs.sort(compareDlcs);
                        resolve(dlcs);
                    });
                }).catch(reject);
            }
        }).catch(reject);
    });
}

function summarizeMapWithThumbnail(mapDoc) {
    return new Promise((resolve, reject) => {
        const mapInfo = mapDoc.data();

        let previewDocName = "preview-thumbnail";
        if (mapInfo.environment.key === "Winter") {
            previewDocName = "preview-image";
        }

        getDoc(doc(firestore, "maps", mapDoc.id, "images", previewDocName)).then((thumbnailDoc) => {
            const thumbnailInfo = thumbnailDoc.data();

            let summary = {
                id: mapDoc.id,
                mapId: mapInfo.id,
                name: mapInfo.name,
                dlcId: mapInfo.dlc.id,
                dlcName: mapInfo.dlc.name,
                envId: mapInfo.environment.id,
                envKey: mapInfo.environment.key,
                envName: mapInfo.environment.name,
                buildableAreaPct: mapInfo.buildableAreaPct,
                fertileLandResourceAmount: mapInfo.fertileLandResourceAmount,
                forestryResourceAmount: mapInfo.forestryResourceAmount,
                oilResourceAmount: mapInfo.oilResourceAmount,
                oreResourceAmount: mapInfo.oreResourceAmount,
                waterAmount: mapInfo.waterAmount,
                maxResourceAmount: mapInfo.maxResourceAmount,
                airwayCount: mapInfo.incomingAirwayCount + mapInfo.outgoingAirwayCount,
                channelCount: mapInfo.incomingChannelCount + mapInfo.outgoingChannelCount,
                railCount: mapInfo.incomingRailCount + mapInfo.outgoingRailCount,
                roadCount: mapInfo.incomingRoadCount + mapInfo.outgoingRoadCount,
            };

            if (!thumbnailInfo) {
                console.log(`${mapDoc.id} DOES NOT HAVE a thumbnail image.`);
                resolve(summary);
                return;
            }

            getDownloadURL(storageRef(storage, thumbnailInfo.path)).then((thumbnailUrl) => {
                resolve({
                    ...summary,
                    thumbnailUrl: thumbnailUrl,
                    thumbnailContentType: thumbnailInfo.mimeType,
                    thumbnailImageType: thumbnailInfo.imgType
                });
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

function fetchImageFromMetadata(imageMetadataDoc) {
    return new Promise((resolve, reject) => {
        const metadata = imageMetadataDoc.data();
        getDownloadURL(storageRef(storage, metadata.path)).then((url) => {
            resolve({
                docId: imageMetadataDoc.id,
                url,
                ...metadata
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

function getImageMetadataScore(imgType) {
    if (imgType === "Preview Image") {
        return 0;
    } else if (imgType === "Overview Map") {
        return 1;
    } else if (imgType === "Resources Map") {
        return 2;
    } else if (imgType === "Fishing Map") {
        return 3;
    } else if (imgType === "Wind Map") {
        return 4;
    } else if (imgType === "Terrain Map") {
        return 5;
    } else if (imgType === "Water Map") {
        return 6;
    } else if (imgType === "Starting-Tile View") {
        return 7;
    }

    return 8;
}

function fetchImages(mapDocId) {
    return new Promise((resolve, reject) => {
        getDocs(query(collection(firestore, "maps", mapDocId, "images"), where("imgType", "!=", "Preview Thumbnail"))).then((imagesSnapshot) => {
            Promise.all(imagesSnapshot.docs.map(fetchImageFromMetadata)).then((imageMetadata) => {
                resolve(imageMetadata.sort((metadata1, metadata2) => {
                    return getImageMetadataScore(metadata1.imgType) - getImageMetadataScore(metadata2.imgType);
                }));
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

function fetchLearningResources(mapDocId) {
    return new Promise((resolve, reject) => {
        getDocs(collection(firestore, "maps", mapDocId, "learningResources")).then((lrSnapshot) => {
            resolve(lrSnapshot.docs.map((lr) => {
                return ({
                    docId: lr.id,
                    ...lr.data()
                });
            }));
        }).catch((error) => {
            reject(error);
        });
    });
}

function collateMapData(mapSnapshot) {
    return new Promise((resolve, reject) => {
        if (!mapSnapshot.data()) {
            reject({
                code: "invalid-map-id",
                message: `Found no maps with ID ${mapSnapshot.id}`,
            });
            return;
        }

        const mapDocId = mapSnapshot.id;
        fetchImages(mapDocId).then((images) => {
            fetchLearningResources(mapDocId).then((learningResources) => {
                const mapInfo = mapSnapshot.data();
                resolve({
                    learningResources,
                    images,
                    ...mapInfo
                });
            }).catch(reject);
        }).catch(reject);
    });
}

export function fetchMap(mapId) {
    let numericMapId = parseInt(mapId);

    if (isNaN(numericMapId)) {
        return new Promise((resolve, reject) => {
            getDoc(doc(firestore, "maps", mapId)).then((mapSnapshot) => {
                collateMapData(mapSnapshot).then(resolve).catch(reject);
            }).catch(reject);
        });
    } else {
        return new Promise((resolve, reject) => {
            getDocs(query(collection(firestore, "maps"), where("id", "==", numericMapId))).then((mapsSnapshot) => {
                if (mapsSnapshot.docs.length !== 1) {
                    reject({
                        code: "invalid-map-id",
                        message: `Found ${mapsSnapshot.docs.length} maps with ID ${mapId}`,
                    });
                } else {
                    collateMapData(mapsSnapshot.docs[0]).then(resolve).catch(reject);
                }
            }).catch(reject);
        });
    }
}

export function fetchMapSummaries() {
    return new Promise((resolve, reject) => {
        getDocs(collection(firestore, "maps")).then((mapsSnapshot) => {
            Promise.all(mapsSnapshot.docs.map(summarizeMapWithThumbnail)).then((summarizedMaps) => {
                // Sort maps by DLC, Environment, and Name
                summarizedMaps.sort((map1, map2) => {
                    // Base Game always goes first, Snowfall always goes last.
                    if (map1.dlcName !== map2.dlcName) {
                        if (map1.dlcName === 'Base Game') {
                            return -1;
                        } else if (map2.dlcName === 'Base Game') {
                            return 1;
                        } else if (map1.dlcName === 'Snowfall') {
                            return 1;
                        } else if (map2.dlcName === 'Snowfall') {
                            return -1;
                        }
                    }

                    // Then sort by DLC name.
                    if (map1.dlcName < map2.dlcName) {
                        return -1;
                    } else if (map1.dlcName > map2.dlcName) {
                        return 1;
                    }

                    // Sort by Environment Name
                    if (map1.envName < map2.envName) {
                        return -1;
                    } else if (map1.envName > map2.envName) {
                        return 1;
                    }

                    // Sort by Name
                    if (map1.name < map2.name) {
                        return -1;
                    } else if (map1.name > map2.name) {
                        return 1;
                    }

                    // We shouldn't get here.
                    console.log("Found a duplicate map summary.  DLC Name, Environment Name, and Map Name were all the same.");
                    return 0;
                });

                resolve(summarizedMaps);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function createDlc(user, dlc) {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }
        addDoc(collection(firestore, "dlcs"), dlc).then((docRef) => resolve(docRef.id)).catch((error) => reject(error));
    });
}

export function showAdminLogin() {
    return new Promise((resolve, reject) => {
        get(child(ref(db), "admin/show")).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                resolve(false);
            }

        }).catch(reject);
    });
}

export function getAdminStatus(field) {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        get(child(ref(db), "admin")).then((snapshot) => {
            if (!snapshot.exists()) {
                resolve(false);
            } else {
                const admin = snapshot.val();
                resolve(admin[field]);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function setAdminStatus(field, value) {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        const adminRef = ref(db, "admin");

        get(adminRef).then((snapshot) => {
            let admin = null;
            if (snapshot.exists()) {
                admin = snapshot.val();
                admin[field] = value;
            } else {
                admin = {
                    [field]: value
                };
            }

            set(adminRef, admin).then(() => {
                resolve(admin);
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export function importMaps() {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        getAdminStatus("mapsImported").then((status) => {
            if (status) {
                reject({
                    code: "alreadyImported",
                    message: "Maps have already been imported"
                });
                return;
            }

            auth.currentUser.getIdToken().then((token) => {
                const fetchConfig = {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                };

                fetch(importMapsUrl, fetchConfig).then((response) => {
                    if (response.ok) {
                        setAdminStatus("mapsImported", true).then(() => {
                            resolve();
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        reject({
                            code: "import-failed",
                            message: "The import failed.  Please check the logs."
                        });
                    }
                }).catch((error) => {
                    reject(error);
                });
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

function uploadImageFile(files, mapDocId, imageDocId, imgType, imageFileName) {
    return new Promise((resolve, reject) => {
        if (files.length === 0) {
            reject({
                "code": "no-files",
                "message": "There are no images to upload",
            });
            return;

        } else if (files.length > 1) {
            reject({
                "code": "multiple-files",
                "message": `Only one ${imgType} image can be uploaded per map.`,
            });
            return;
        }

        let filename = files[0].name;
        let imageExt = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || '';
        let imagePath = `images/${mapDocId}/${imageFileName}.${imageExt}`;
        let imageRef = storageRef(storage, imagePath);

        const imageMetadata = {
            mapDocId,
            imageDocId,
            imgType,
            name: `${imageFileName}.${imageExt}`,
            path: imagePath,
            mimeType: files[0].type,
        };

        getDownloadURL(imageRef).then((downloadUrl) => {
            resolve(imageMetadata);
        }).catch((error) => {
            uploadBytes(imageRef, files[0]).then((snapshot) => {
                resolve(imageMetadata);
            }).catch(reject);
        });
    });
}

function getEnvNameByKey(env) {
    let name = null;

    if (env === "North") {
        name = "Boreal";

    } else if (env === "Sunny") {
        name = "Temperate";

    } else if (env === "Europe") {
        name = "European";

    } else {
        name = env;
    }

    return name;
}

function getEnvGeoPointByKey(env) {
    let geoPoint = null;

    if (env === "Winter") {
        geoPoint = new GeoPoint(66, 98);

    } else if (env === "North") {
        geoPoint = new GeoPoint(66, 98);

    } else if (env === "Europe") {
        geoPoint = new GeoPoint(40, 98);

    } else if (env === "Sunny") {
        geoPoint = new GeoPoint(35, 98);

    } else if (env === "Tropical") {
        geoPoint = new GeoPoint(23, 98);
    }

    return geoPoint;
}

export function addMap(metadataFiles, previewImageFiles, overviewMapFiles, resourcesMapFiles, fishingMapFiles, windMapFiles, terrainMapFiles, waterMapFiles, startingTileFiles) {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        if (metadataFiles.length !== 1) {
            reject({
                code: "need-exactly-one-metadata-file",
                message: `Exactly one metadata file should be selected, but found ${metadataFiles.length}`
            });
            return;
        }

        // 1. Parse Metadata
        let xmlFileReader = new FileReader();
        xmlFileReader.onload = (event) => {
            let xml = event.target.result;
            let xmlParser = new Parser();
            xmlParser.parseString(xml, (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }

                let mapDocId = result.map["$"]["name"].replace(/\s+/g, '-').toLowerCase();
                let dlcName = result.map.dlc[0];
                let env = result.map.environment[0];
                let envGeoPoint = getEnvGeoPointByKey(env);

                if (!envGeoPoint) {
                    reject({
                        code: "invalid-env-key",
                        message: `Environment '${env}' is not recognized.`,
                    });
                    return;
                }

                getDocs(query(collection(firestore, "dlcs"), where("name", "==", dlcName))).then((dlcsSnapshot) => {
                    if (dlcsSnapshot.docs.length !== 1) {
                        reject({
                            code: "invalid-dlc-count",
                            message: `Expected 1 DLC with the name '${dlcName}', but found ${dlcsSnapshot.docs.length}`,
                        });
                        return;
                    }

                    const dlcDoc = dlcsSnapshot.docs[0];
                    const dlcId = dlcDoc.id;
                    const dlcData = dlcDoc.data();

                    const envDetails = result.map.envDetails[0];

                    let newMap = {
                        name: result.map["$"]["name"],
                        timestamp: Timestamp.fromMillis(Date.parse(result.map.timestamp[0])),
                        buildableAreaPct: parseInt(result.map.buildableArea[0]),
                        maxResourceAmount: parseInt(result.map.maxResourceAmount[0]),
                        forestryResourceAmount: parseInt(result.map.forestryAmount[0]),
                        fertileLandResourceAmount: parseInt(result.map.fertileLandAmount[0]),
                        oreResourceAmount: parseInt(result.map.oreAmount[0]),
                        oilResourceAmount: parseInt(result.map.oilAmount[0]),
                        waterAmount: parseInt(result.map.waterAmount[0]),
                        incomingRoadCount: parseInt(result.map.incomingRoads[0]),
                        outgoingRoadCount: parseInt(result.map.outgoingRoads[0]),
                        incomingRailCount: parseInt(result.map.incomingTrainTracks[0]),
                        outgoingRailCount: parseInt(result.map.outgoingTrainTracks[0]),
                        incomingChannelCount: parseInt(result.map.incomingShipPath[0]),
                        outgoingChannelCount: parseInt(result.map.outgoingShipPath[0]),
                        incomingAirwayCount: parseInt(result.map.incomingPlanePath[0]),
                        outgoingAirwayCount: parseInt(result.map.outgoingPlanePath[0]),
                        dlc: {
                            id: dlcId,
                            name: dlcData.name,
                            iconPath: dlcData.iconPath,
                        },
                        environment: {
                            key: env,
                            name: getEnvNameByKey(env),
                            geoPoint: getEnvGeoPointByKey(env),
                            minimumDaytimeTemperature: parseInt(envDetails.minTemperatureDay[0]["_"]),
                            maximumDaytimeTemperature: parseInt(envDetails.maxTemperatureDay[0]["_"]),
                            minimumNighttimeTemperature: parseInt(envDetails.minTemperatureNight[0]["_"]),
                            maximumNighttimeTemperature: parseInt(envDetails.maxTemperatureNight[0]["_"]),
                            minimumTemperatureDuringRain: parseInt(envDetails.minTemperatureRain[0]["_"]),
                            maximumTemperatureDuringRain: parseInt(envDetails.maxTemperatureRain[0]["_"]),
                            minimumTemperatureDuringFog: parseInt(envDetails.minTemperatureFog[0]["_"]),
                            maximumTemperatureDuringFog: parseInt(envDetails.maxTemperatureFog[0]["_"]),
                            daytimeRainProbability: parseInt(envDetails.rainProbabilityDay[0]["_"]),
                            nighttimeRainProbability: parseInt(envDetails.rainProbabilityNight[0]["_"]),
                            daytimeFogProbability: parseInt(envDetails.fogProbabilityDay[0]["_"]),
                            nighttimeFogProbability: parseInt(envDetails.fogProbabilityNight[0]["_"]),
                            northernLightsProbability: parseInt(envDetails.northernLightsProbability[0]["_"]),
                        },
                        milestones: result.map.milestones[0].milestone.map((milestone, index) => {
                            const key = milestone["$"].name;

                            let name = null;
                            if (key === "Milestone1 Requirement 1") {
                                name = "Little Hamlet";

                            } else if (key === "Milestone2 Requirement 1") {
                                name = "Worthy Village";

                            } else if (key === "Milestone3 Requirement 1") {
                                name = "Tiny Town";

                            } else if (key === "Milestone4 Requirement 1") {
                                name = "Boom Town";

                            } else if (key === "Milestone5 Requirement 1") {
                                name = "Busy Town";

                            } else if (key === "Milestone6 Requirement 1") {
                                name = "Big Town";

                            } else if (key === "Milestone7 Requirement 1") {
                                name = "Small City";

                            } else if (key === "Milestone8 Requirement 1") {
                                name = "Big City";

                            } else if (key === "Milestone9 Requirement 1") {
                                name = "Grand City";

                            } else if (key === "Milestone10 Requirement 1") {
                                name = "Capital City";

                            } else if (key === "Milestone11 Requirement 1") {
                                name = "Colossal City";

                            } else if (key === "Milestone12 Requirement 1") {
                                name = "Metropolis";

                            } else if (key === "Milestone13 Requirement 1") {
                                name = "Megalopolis";

                            } else {
                                name = "<Invalid Key>";
                            }

                            return {
                                id: index + 1,
                                key,
                                name,
                                populationTarget: parseInt(milestone["_"]),
                            };
                        }),
                    };

                    console.log("Saving Map", newMap.name);

                    const mapDocRef = doc(firestore, "maps", mapDocId);
                    setDoc(mapDocRef, newMap).then((mapSnapshot) => {
                        console.log("Updating DLC", dlcId);

                        const dlcMapDocRef = doc(firestore, "dlcs", dlcId, "maps", mapDocId);
                        setDoc(dlcMapDocRef, {
                            name: newMap.name,
                        }).then((dlcMapAddSnapshot) => {
                            console.log("Processing the images.");

                            // 2. Upload images
                            let previewImagePromise = uploadImageFile(previewImageFiles, mapDocId, "preview-image", "Preview Image", "preview");
                            let overviewMapPromise = uploadImageFile(overviewMapFiles, mapDocId, "overview-map", "Overview Map", "overview");
                            let fishingMapPromise = uploadImageFile(fishingMapFiles, mapDocId, "fishing-map", "Fishing Map", "fishing");
                            let resourcesMapPromise = uploadImageFile(resourcesMapFiles, mapDocId, "resources-map", "Resources Map", "resources");
                            let windMapPromise = uploadImageFile(windMapFiles, mapDocId, "wind-map", "Wind Map", "wind");
                            let terrainMapPromise = uploadImageFile(terrainMapFiles, mapDocId, "terrain-map", "Terrain Map", "terrain");
                            let waterMapPromise = uploadImageFile(waterMapFiles, mapDocId, "water-map", "Water Map", "water");
                            let startingTilePromise = uploadImageFile(startingTileFiles, mapDocId, "starting-tile-view", "Starting-Tile View", "starting-tile");

                            Promise.all([previewImagePromise, overviewMapPromise, fishingMapPromise, resourcesMapPromise, windMapPromise, terrainMapPromise, waterMapPromise, startingTilePromise]).then((values) => {
                                console.log("All images uploaded; creating metadata.");
                                let batch = writeBatch(firestore);

                                values.forEach((record) => {
                                    const docRef = doc(firestore, "maps", record.mapDocId, "images", record.imageDocId);
                                    batch.set(docRef, {
                                        imgType: record.imgType,
                                        mimeType: record.mimeType,
                                        name: record.name,
                                        path: record.path,
                                    });
                                });

                                console.log("Uploaded images and collected metadata; committing the batch.");

                                batch.commit().then(() => {
                                    resolve(mapDocId);

                                }).catch(reject);
                            }).catch(reject);
                        }).catch(reject);
                    }).catch(reject);
                }).catch(reject);
            });
        }

        xmlFileReader.readAsText(metadataFiles[0]);
    });
}

export function uploadLearningResourcesCsv(prefix, files) {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        if (files.length !== 1) {
            reject({
                code: "wrong-csv-file-count",
                message: `Expected 1 CSV to upload, but found ${files.length}`,
            });
            return;
        }

        if (!prefix) {
            reject({
                code: "no-prefix",
                message: "A prefix is needed to uniquely identify the learning resources.",
            })
            return;
        }

        Papa.parse(files[0], {
            error: (error) => {
                reject(error);
            },
            complete: (results) => {
                // Format:
                // 1st row: Header
                // 1st col: Map Name
                // All other cols: empty or YouTube link.
                let learningResourcesByMapName = {};
                let mapNames = [];

                for (let rowIndex = 1; rowIndex < results.data.length; ++rowIndex) {
                    const mapName = results.data[rowIndex][0];
                    if (!mapName) {
                        // Skip blank lines.
                        continue;
                    }

                    let mapLearningResources = [];
                    for (let colIndex = 1; colIndex < results.data[rowIndex].length; ++colIndex) {
                        if (results.data[rowIndex][colIndex]) {
                            mapLearningResources.push(results.data[rowIndex][colIndex]);
                        }
                    }

                    mapNames.push(mapName);
                    learningResourcesByMapName[mapName] = mapLearningResources;
                }

                let docCollectionQuery = null;
                if (mapNames.length <= 10) {
                    // The 'in' clause has a maximum of 10 elements.
                    docCollectionQuery = query(collection(firestore, "maps"), where('name', 'in', mapNames));
                } else {
                    // If more than 10, just fetch them all.
                    docCollectionQuery = collection(firestore, "maps");
                }

                getDocs(docCollectionQuery).then((mapsSnapshot) => {
                    let learningResourcesByMapDocId = {};
                    mapsSnapshot.docs.forEach((mapDoc) => {
                        const learningResources = learningResourcesByMapName[mapDoc.data().name];
                        if (learningResources && learningResources.length) {
                            learningResourcesByMapDocId[mapDoc.id] = learningResources;
                        }
                    });

                    let batch = writeBatch(firestore);

                    for (const mapId in learningResourcesByMapDocId) {
                        const learningResources = learningResourcesByMapDocId[mapId];

                        for (let lrIdx = 0; lrIdx < learningResources.length; ++lrIdx) {

                            const lr = learningResources[lrIdx];
                            let category = null;
                            let uri = null;

                            if (lr.includes("?list=")) {
                                category = "YouTube Playlist";
                                uri = `https://www.youtube.com/embed/videoseries${lr.substring(lr.indexOf("?list="))}`;

                            } else if (lr.includes("?v=")) {
                                category = "YouTube Video";
                                uri = `https://www.youtube.com/embed/${lr.substring(lr.indexOf("?v=") + 3)}`;

                            } else {
                                continue;
                            }

                            batch.set(doc(firestore, "maps", mapId, "learningResources", `${prefix}-${lrIdx + 1}`), {
                                category,
                                uri,
                            });
                        }
                    }

                    batch.commit().then(() => {
                        resolve();
                    }).catch(reject);
                }).catch(reject);
            },
        });
    });
}

export function buildMenu() {
    return new Promise((resolve, reject) => {
        if (!isAdminLoggedIn(reject)) {
            return;
        }

        auth.currentUser.getIdToken().then((token) => {
            const fetchConfig = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            fetch(buildMenuUrl, fetchConfig).then((response) => {
                if (response.ok) {
                    resolve();
                } else {
                    reject({
                        code: "build-menu-failed",
                        message: "Building the menu failed.  Please check the logs."
                    });
                }
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}