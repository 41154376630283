import React, { useState } from "react";
import { login } from "../api";

const LogInForm = ({ hideLogin, setHideLogin, onUserLogIn }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const onLogin = (event) => {
        event.preventDefault();
        login(username, password).then((user) => onUserLogIn(user));
    }

    return (
        <div className={`show ${hideLogin ? "inactive" : ""}`}>
            <div className="login-form">
                <div className="form-box solid">
                    <form>
                        <span className="login-box-close" onClick={(event) => setHideLogin(true)} >X</span>
                        <h1 className="login-text">Sign In</h1>
                        <label>Username</label>
                        <br></br>
                        <input type="text" name="username" className="login-box" onChange={(event) => setUsername(event.target.value)} />
                        <br></br>
                        <label>Password</label>
                        <br></br>
                        <input type="password" name="password" className="login-box" onChange={(event) => setPassword(event.target.value)} />
                        <br></br>
                        <input type="submit" value="LOGIN" className="login-btn" onClick={onLogin} />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LogInForm;