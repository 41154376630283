import React, { useState, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { uploadLearningResourcesCsv } from "../../api";

export default function AddLearningResources() {
    const [prefix, setPrefix] = useState(null);
    const [message, setMessage] = useState(null);
    const resourceFileInput = useRef(null);

    let messageBox = null;
    if (message) {
        messageBox = (
            <Row>
                <Col>
                    {message}
                </Col>
            </Row>
        );
    }

    function onUploadResources(event) {
        event.preventDefault();

        uploadLearningResourcesCsv(prefix, resourceFileInput.current.files).then(() => {
            setMessage("File uploaded");
        }).catch((error) => {
            console.error(error);
            setMessage(error.message);
        });
    }

    return (
        <div>
            <h2>Upload Learning Resources</h2>
            {messageBox}
            <form onSubmit={onUploadResources}>
                <Container>
                    <Row>
                        <Col>
                            <label>
                                Group Prefix
                                <input type="text" id="prefix" value={prefix} onChange={(event) => setPrefix(event.target.value)} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Learning Resources CSV
                                <input type="file" id="learning-resources" name="learning-resources" accept="text/csv" ref={resourceFileInput} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="submit" value="Upload Learning Resources" />
                        </Col>
                    </Row>
                </Container>
            </form>
        </div>
    );
}