import React from "react";
import AddDlc from "../Components/Admin/AddDlc";

export default function AdminHome({ user }) {
    return (
        <div>
            <h1>Admin Panel</h1>
            <AddDlc user={user} />
        </div>
    );
}
