import React from "react";
import AddLearningResources from "../../Components/Admin/AddLearningResources";

export default function AdminLearningResources() {
    return (
        <div>
            <h1>Learning Resources</h1>
            <AddLearningResources />
        </div>
    );
}
