import React, { useState } from "react";
import { Link } from "react-router-dom";
import { showAdminLogin } from "../api";

export default function Nav({ dlcs, user, handleLogInClick }) {
    const [showAdminPanel, setShowAdminPanel] = useState(false);
    const [queriedAdminPanel, setQueriedAdminPanel] = useState(false);

    if (!queriedAdminPanel) {
        showAdminLogin().then((show) => {
            setShowAdminPanel(show);
            setQueriedAdminPanel(true);
        }).catch((error) => {
            console.error(error);
            setQueriedAdminPanel(true);
        });
    }

    const handleClick = () => {
        handleLogInClick();
    };

    let adminPanel = null;
    if (showAdminPanel) {
        if (user) {
            adminPanel = (
                <div className="collapse navbar-collapse flex-grow-1 text-right" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto flex-nowrap">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/admin" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Admin
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="/admin/dlcs">Manage DLCs</a>
                                <a class="dropdown-item" href="/admin/maps">Manage Maps</a>
                                <a class="dropdown=item" href="/admin/learning-resources">Learning Resources</a>
                            </div>
                        </li>
                    </ul>
                </div>
            );
        } else {
            adminPanel = (
                <span onClick={handleClick} className="login-btn">&nbsp;Log In&nbsp;</span>
            );
        }
    }

    const dlcMenus = dlcs.map((dlc) => {
        const mapList = dlc.maps?.map((map) => {
            return (
                <Link class="dropdown-item" to={`/maps/${map.id}`}>{map.name}</Link>
            );
        });

        return (
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={dlc.iconPath} alt={`${dlc.name}; Copyright &#169; &#xA9; &copy; 2014 Paradox Interactive AB. www.paradoxplaza.com`}></img>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    {mapList}
                </div>
            </li>
        );
    });

    return (
        <nav className="navbar navbar-dark bg-dark navbar-expand-md">
            <a className="navbar-brand" href="/"><span class="skylines">Skylines</span>&nbsp;<span class="country">Country</span></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse flex-grow-1 text-right" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto flex-nowrap">
                    {dlcMenus}
                </ul>
            </div>
            <div>
                {adminPanel}
            </div>
        </nav>
    );
}