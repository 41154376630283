import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fetchMapSummaries, listDlcs } from "../api";

export default function Home() {
    const [dlcs, setDlcs] = useState([]);
    const [mapsByDlc, setMapsByDlc] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);

    function groupMapsByDlc(dlcs, mapSummaries) {
        let mapsByDlc = {};

        dlcs.forEach((dlc) => {
            mapsByDlc[dlc.name] = [];
        });

        mapSummaries.forEach((map) => {
            mapsByDlc[map.dlcName].push(map);
        });

        return mapsByDlc;
    }

    useEffect(() => {
        if (!fetchedData) {
            fetchMapSummaries().then((mapSummaries) => {
                listDlcs().then((dlcs) => {
                    const newMapByDlcs = groupMapsByDlc(dlcs, mapSummaries);
                    setDlcs(dlcs);
                    setMapsByDlc(newMapByDlcs);
                    setFetchedData(true);
                });
            }).catch((error) => {
                setFetchedData(true);
            });
        }
    }, []);

    function displayResourceProgress(resource, imageUrl, resourceAmount, maxResourceAmount, cssClass) {
        return (
            <p>
                <div className="resource-label">
                    <img src={imageUrl} width="30" height="30" alt={`${resource}; Copyright &copy; 2014 Paradox Interactive AB. www.paradoxplaza.com`}></img>
                </div>
                <div className="progress resource-progress">
                    <div
                        className={`progress-bar ${cssClass}`}
                        role="progressbar"
                        style={{ width: `${(resourceAmount / maxResourceAmount * 100)}%` }}
                        aria-valuenow={resourceAmount}
                        aria-valuemin="0"
                        aria-valuemax={maxResourceAmount}>
                    </div>
                </div>
            </p>
        );
    }

    function displayTransitImages(map) {
        let images = [];

        if (map.roadCount > 0) {
            images.push(
                <>
                    <img src="/icons/highway.svg" width="30" height="30" alt="Highway Connection"></img>&nbsp;
                </>
            );
        }

        if (map.railCount > 0) {
            images.push(
                <>
                    <img src="/icons/rails.svg" width="30" height="30" alt="Rail Connection"></img>&nbsp;
                </>
            );
        }

        if (map.channelCount > 0) {
            images.push(
                <>
                    <img src="/icons/ship.svg" width="30" height="30" alt="Shipping Channel Connection"></img>&nbsp;
                </>
            )
        }

        if (map.airwayCount > 0) {
            images.push(
                <>
                    <img src="/icons/plane.svg" width="30" height="30" alt="Airway Connection"></img>&nbsp;
                </>
            );
        }

        return (
            <p style={{ textAlign: "center" }}>
                {images}
            </p >
        );
    }

    function displayMap(map) {
        let thumbnail = null;
        if (map.thumbnailUrl) {
            thumbnail = (
                <LazyLoadImage
                    className="card-img-top"
                    src={map.thumbnailUrl}
                    alt={`${map.thumbnailImageType}; Copyright &copy; 2014 Paradox Interactive AB. www.paradoxplaza.com`}
                    effect="opacity"
                />
            );
        }

        return (
            <div className="card" style={{ width: "18rem;" }}>
                <div className="card-header">
                    <h5 className="card-title">{map.name}</h5>
                </div>
                {thumbnail}
                <div className="card-body">
                    {displayTransitImages(map)}
                    <p className="card-text">Buildable Land Area: {map.buildableAreaPct}%
                        {displayResourceProgress("Oil", "/icons/oil.png", map.oilResourceAmount, map.maxResourceAmount, "bg-danger")}
                        {displayResourceProgress("Ore", "/icons/ore.png", map.oreResourceAmount, map.maxResourceAmount, "bg-info")}
                        {displayResourceProgress("Fertile Land", "/icons/fertile_land.png", map.fertileLandResourceAmount, map.maxResourceAmount, "bg-warning")}
                        {displayResourceProgress("Forestry", "/icons/forestry.png", map.forestryResourceAmount, map.maxResourceAmount, "bg-success")}
                        {displayResourceProgress("Water", "/icons/water.png", map.waterAmount, map.maxResourceAmount, "bg-primary")}
                    </p>
                    <a href={`/maps/${map.id}`} class="btn btn-primary">View Details</a>
                </div>
            </div>
        );
    }

    function displayMapsForEnvironment(envKey, maps) {
        return maps.filter((map) => map.envKey === envKey).map(displayMap);
    }

    function displayMapsForDlc(dlcName, dlcImgPath, maps) {
        const displayBorealMaps = displayMapsForEnvironment('North', maps);
        const displayEuropeMaps = displayMapsForEnvironment('Europe', maps);
        const displayTemperateMaps = displayMapsForEnvironment('Sunny', maps);
        const displayTropicalMaps = displayMapsForEnvironment('Tropical', maps);

        return (
            <>
                <tr>
                    <th colspan="4" scope="row" class="map-section">
                        <img src={dlcImgPath} alt={`${dlcName}; Copyright &copy; 2014 Paradox Interactive AB. www.paradoxplaza.com`}></img>
                    </th>
                </tr>
                <tr>
                    <th scope="col">Boreal</th>
                    <th scope="col">European</th>
                    <th scope="col">Temperate</th>
                    <th scope="col">Tropical</th>
                </tr>
                <tr>
                    <td>
                        {displayBorealMaps}
                    </td>
                    <td>
                        {displayEuropeMaps}
                    </td>
                    <td>
                        {displayTemperateMaps}
                    </td>
                    <td>
                        {displayTropicalMaps}
                    </td>
                </tr>
            </>
        );
    }

    function displayMapsForWinterDlc(dlcName, dlcImagePath, maps) {
        const displayMaps = maps.map(displayMap);

        return (
            <>
                <tr>
                    <th colspan="4" scope="row" className="map-section">
                        <img src={dlcImagePath} alt={`${dlcName}; Copyright &copy; 2014 Paradox Interactive AB. www.paradoxplaza.com`}></img>
                    </th>
                </tr>
                <tr>
                    <th colspan="4" scope="col">Winter</th>
                </tr>
                <tr>
                    <td colspan="4">
                        <div className="card-columns">
                            {displayMaps}
                        </div>
                    </td>
                </tr>
            </>
        );
    }

    const displayDlcs = dlcs.filter((dlc) => dlc.name !== 'Snowfall').map((dlc) => {
        return displayMapsForDlc(dlc.name, dlc.bannerPath, mapsByDlc[dlc.name]);
    });

    const displayWinterDlc = dlcs.filter((dlc) => dlc.name === 'Snowfall').map((dlc) => {
        return displayMapsForWinterDlc(dlc.name, dlc.bannerPath, mapsByDlc[dlc.name]);
    });

    return (
        <div>
            <h1>Welcome to <span class="skylines">Skylines</span> <span class="country">Country</span>!</h1>
            <div class="container">
                <div class="row">
                    <div class="col-md">
                        <p>
                            <a href="https://www.citiesskylines.com/"><span class="cities">Cities</span>: <span class="skylines">Skylines</span></a> is a
                            city-builder game developed in <a href="https://unity.com/">Unity3D</a> by <a href="https://www.colossalorder.fi/">Colossal Order</a>
                            and published by <a href="https://www.paradoxplaza.com/">Paradox Interactive</a>. Learn more at the
                            <a href="https://skylines.paradoxwikis.com/Cities:_Skylines_Wiki">official wiki</a>.
                        </p>
                        <p>
                            <em>All official Cities: Skylines content copyright &copy; 2014 Paradox Interactive AB.</em>
                        </p>
                    </div>
                    <div class="col-md">
                        <p>
                            <span class="skylines">Skylines</span> <span class="country">Country</span> provides city-build planning information for all
                            in-game maps, including:
                            <ul>
                                <li>Map Views</li>
                                <li>Resource Availability</li>
                                <li>Milestone Population Requirements</li>
                                <li>Map Temperature Ranges</li>
                                <li>Probabilities of Atmospheric Events</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>

            <h2>Maps</h2>

            <table class="table table-striped table-bordered table-hover">
                <tbody>
                    {displayDlcs}
                    {displayWinterDlc}
                </tbody>
            </table>
        </div>
    );
}