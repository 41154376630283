import React, { useState } from "react";
import { buildMenu } from "../../api";

export default function BuildMenu() {
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const initiateBuildingMenu = (event) => {
        buildMenu().then(() => {
            setMessage("Building the menu completed successfully.");
        }).catch((error) => {
            setError(error.message);
        });
    };

    let messageBox = null;
    if (message) {
        messageBox = (
            <div>{message}</div>
        );
    }

    let errorBox = null;
    if (error) {
        errorBox = (
            <div style={{ color: "red" }}>{error}</div>
        );
    }

    return (
        <div>
            <h2>Build Menu</h2>
            {messageBox}
            {errorBox}
            <button onClick={initiateBuildingMenu}>Build Menu</button>
        </div>
    );
}