import React, { useState } from "react";
import { listDlcs, createDlc } from "../../api";

export default function AddDlc({ user }) {
    const [dlcList, setDlcList] = useState(null);
    const [dlcName, setDlcName] = useState("");
    const [iconPath, setIconPath] = useState("/icons/");
    const [bannerPath, setBannerPath] = useState("/banners/");
    const [docId, setDocId] = useState(null);
    const [error, setError] = useState(null);

    if (!user) {
        return null;
    }

    const loadDlcs = () => {
        listDlcs().then((dlcs) => setDlcList(dlcs)).catch((error) => {
            console.log(error);
            setDlcList([]);
        });
    }

    if (dlcList === null) {
        loadDlcs();
    }

    const onCreateDlc = (event) => {
        event.preventDefault();
        createDlc(user, {
            name: dlcName,
            iconPath: iconPath,
            bannerPath: bannerPath
        }).then((docId) => {
            setDocId(docId);
            loadDlcs();
        }).catch((error) => {
            console.log("Error; code: ", error.code);
            setError(error.message);
        });
    }

    const addAnotherDlc = (event) => {
        setDlcName("");
        setIconPath("");
        setBannerPath("");
        setDocId(null);
    }

    let dlcListBox = null;
    if (dlcList !== null) {
        const dlcListView = dlcList.map((dlc) => {
            return (
                <tr>
                    <td>{dlc.id}</td>
                    <td>{dlc.name}</td>
                    <td>
                        <img src={dlc.iconPath} alt="icon" />
                        <br />
                        {dlc.iconPath}
                    </td>
                    <td>
                        <img src={dlc.bannerPath} alt="banner" />
                        <br />
                        {dlc.bannerPath}
                    </td>
                </tr>
            );
        });

        dlcListBox = (
            <div>
                <h3>Existing DLCs</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Doc ID</th>
                            <th>Name</th>
                            <th>Icon Path</th>
                            <th>Banner Path</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dlcListView}
                    </tbody>
                </table>
            </div>
        );
    }

    let docBox = null;
    if (docId) {
        docBox = (
            <div>
                <p>
                    DLC {dlcName} was created with document ID {docId}
                </p>
                <button onClick={addAnotherDlc}>Add Another DLC</button>
            </div>
        );
    }

    let errorBox = null;
    if (error) {
        <div style={{ color: 'red' }}>{error}</div>
    }

    return (
        <div>
            <h2>DLCs</h2>
            {dlcListBox}
            <h3>Add New DLC</h3>
            <form onSubmit={onCreateDlc}>
                <label>Name</label>
                <input type="text" name="name" value={dlcName} onChange={(event) => setDlcName(event.target.value)} />
                <label>Icon Image Path</label>
                <input type="text" name="icon" value={iconPath} onChange={(event) => setIconPath(event.target.value)} />
                <label>Banner Image Path</label>
                <input type="text" name="banner" value={bannerPath} onChange={(event) => setBannerPath(event.target.value)} />
                <br />
                <input type="submit" value="Add DLC" />
            </form>
            {docBox}
            {errorBox}
        </div>
    );
}