import React from "react";
import AddDlc from "../../Components/Admin/AddDlc";
import BuildMenu from "../../Components/Admin/BuildMenu";

export default function DLCs({ user }) {
    return (
        <div>
            <AddDlc user={user} />
            <hr />
            <BuildMenu />
        </div>
    );
}
