import React, { useState, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { addMap } from "../../api";

export default function AddMap() {
    const [message, setMessage] = useState(null);
    const metadataInput = useRef(null);
    const previewImageInput = useRef(null);
    const overviewMapInput = useRef(null);
    const resourcesMapInput = useRef(null);
    const fishingMapInput = useRef(null);
    const windMapInput = useRef(null);
    const terrainMapInput = useRef(null);
    const waterMapInput = useRef(null);
    const startingTileInput = useRef(null);

    let messageBox = null;
    if (message) {
        messageBox = (
            <Row>
                <Col>
                    {message}
                </Col>
            </Row>
        );
    }

    function onCreateMap(event) {
        event.preventDefault();

        addMap(
            metadataInput.current.files,
            previewImageInput.current.files,
            overviewMapInput.current.files,
            resourcesMapInput.current.files,
            fishingMapInput.current.files,
            windMapInput.current.files,
            terrainMapInput.current.files,
            waterMapInput.current.files,
            startingTileInput.current.files).then((mapDocId) => {
                setMessage(`Added new map ${mapDocId}`);
            }).catch((error) => {
                console.error(error);
                setMessage("An error occurred during the upload.");
            });
    }

    return (
        <div>
            <h2>Add New Map</h2>
            <form onSubmit={onCreateMap}>
                <Container>
                    {messageBox}
                    <Row>
                        <Col>
                            <label>
                                Metadata Document
                                <input type="file" id="metadata" name="metadata" accept="text/xml" ref={metadataInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Preview Image
                                <input type="file" id="preview-image" name="preview-image" accept="image/png, image/jpeg" ref={previewImageInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Overview Map
                                <input type="file" id="overview-map" name="overview-map" accept="image/png, image/jpeg" ref={overviewMapInput} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>
                                Resources Map
                                <input type="file" id="resources-map" name="resources-map" accept="image/png, image/jpeg" ref={resourcesMapInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Fishing Map
                                <input type="file" id="fishing-map" name="fishing-map" accept="image/png, image/jpeg" ref={fishingMapInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Wind Map
                                <input type="file" id="wind-map" name="wind-map" accept="image/png, image/jpeg" ref={windMapInput} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>
                                Terrain Map
                                <input type="file" id="terrain-map" name="terrain-map" accept="image/png, image/jpeg" ref={terrainMapInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Water Map
                                <input type="file" id="water-map" name="water-map" accept="image/png, image/jpeg" ref={waterMapInput} />
                            </label>
                        </Col>
                        <Col>
                            <label>
                                Starting-Tile View
                                <input type="file" id="starting-tile-view" name="starting-tile-view" accept="image/png, image/jpeg" ref={startingTileInput} />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="submit" value="Add Map" />
                        </Col>
                    </Row>
                </Container>
            </form>
        </div>
    );
}