import React, { useState } from "react";
import { getAdminStatus, importMaps } from "../../api";

export default function ImportMaps({ user }) {
    const [mapsImported, setMapsImported] = useState(true);
    const [mapsImporting, setMapsImporting] = useState(false);
    const [checkedIfMapsImported, setCheckedIfMapsImported] = useState(false);
    const [error, setError] = useState(null);

    if (!checkedIfMapsImported) {
        getAdminStatus("mapsImported").then((status) => {
            setCheckedIfMapsImported(true);
            setMapsImported(status);
            if (status) {
                setError("Maps have already been imported.");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const startMapImport = (event) => {
        setMapsImporting(true);
        importMaps().then(() => {
            setMapsImported(true);
            setMapsImporting(false);
        }).catch((error) => {
            setError(error.message);
        });
    };

    let errorBox = null;
    if (error) {
        errorBox = (
            <div style={{ color: "red" }}>{error}</div>
        );
    }

    return (
        <div>
            <h2>Import Maps</h2>
            <button disabled={mapsImported || mapsImporting} onClick={startMapImport}>Import Maps</button>
            {errorBox}
        </div>
    );
}